<template>
    <div class="team_section team_upper">
        <b-container fluid class="team_container">
            <b-row class="team_wrap" align-v="center">
                <b-col lg="6" md="12" class="teamD_wrap">
                    <hr>
                </b-col>
                <b-col lg="4" class="teamD_wrap bio_wrap d-block d-lg-none">
                    <h2>Agustín Alvarado.</h2>
                    <h3>Presidente Akko Group México</h3>
                </b-col>
                <b-col lg="6" md="12" class="teamD_wrap">
                    <b-list-group horizontal class="sociales">
                        <b-list-group-item>
                            <p>Follow:</p>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-link href="#foo"><b-img :src="require('@/assets/img/svg/ico-fb-color.svg')"></b-img></b-link>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-link href="#foo"><b-img :src="require('@/assets/img/svg/ico-insta-color.svg')"></b-img></b-link>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-link href="#foo"><b-img :src="require('@/assets/img/svg/ico-linked-color.svg')"></b-img></b-link>
                        </b-list-group-item>
                        <b-list-group-item>
                            <b-link href="#foo"><b-img :src="require('@/assets/img/svg/ico-email-color.svg')"></b-img></b-link>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
            <b-row class="team_wrap" align-v="center">
                <b-col lg="4" class="teamD_wrap bio_wrap d-none d-lg-block">
                    <h2>Agustín Alvarado.</h2>
                    <h3>Presidente Akko Group México</h3>
                </b-col>
                <b-col lg="8" class="teamD_wrap bio_wrap">
                    
                </b-col>
            </b-row>
            <b-row class="team_wrap">
                <b-col lg="4" class="teamD_wrap bio_wrap">
                    <div class="img_wrap">
                        <b-img :src="require('@/assets/img/equipo/equipo.jpg')" fluid alt="Responsive image"></b-img>
                    </div>
                </b-col>
                <b-col lg="8" class="teamD_wrap bio_wrap">
                    <p>Agustín inició su carrera inmobiliaria en 1998 como Director de Desarrollo en SARE Group Inmobiliario. En 1994 asumió la presidencia de CB commercial de México, que en el 2000 fue filial de la empresa  
                    Internacional más grande del mundo en bienes raíces comerciales. 
                    Dirigió la campañía durante 13 años y la llevo al liderazgo en diversas líneas de negocio en distintas ciudades del país; posicionándola como la empresa líder en el sector Inmobiliario en México. Fundó la revista ICONOS en 2006 y en 2008 obtuvo la vicepresidencia en la cual llevó a cabo exitosamente un programa de disposicón de activos del grupo. En 2012 funda Akko Group con presencia en las ciudades de Miami y México. </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>
.team_section{
    padding-top: 200px;
    padding-bottom: 130px;
}
.team_container .team_wrap hr{
    border-color: var(--primario);
}


.team_container .team_wrap .bio_wrap h2{
    font-size: 50px;
    font-weight: 100;
    line-height: 1.2;
    letter-spacing: 0.05px;
    color: var(--texto);
}
.team_container .team_wrap .bio_wrap h3{
    font-size: 21px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.63px;
}
.team_container .team_wrap .bio_wrap p{
    font-size: 29px;
    font-weight: 300;
    line-height: 1.52;
    color: var(--texto);
}
.team_container .team_wrap .bio_wrap .img_wrap{
    position: relative;
    padding-top: 85%;
    margin: 0 15% 0 0;
}
.team_container .team_wrap .bio_wrap .img_wrap img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.team_container .team_wrap .sociales{
    justify-content: flex-end;
    align-items: center;
}
.team_container .team_wrap .sociales p{
    font-size: 20px;
    line-height: 2.2;
    font-style: italic;
    margin-bottom: 0;
}
.team_container .team_wrap .sociales .list-group-item{
    border: none;
    line-height: 1;
}
.team_container .team_wrap .sociales img{
    width: 33px;
    height: 33px;
    transition: 0.3s ease-out all;
}
.team_container .team_wrap .sociales img:hover{
    transform: scale(1.2);
}
@media(max-width: 992px){
    .team_container .team_wrap{
        justify-content: center;
    }
    .team_container .team_wrap .teamD_wrap{
        padding: 0 65px;
    }
    .team_container .team_wrap .bio_wrap .img_wrap{
        padding-top: 70%;
        margin: 0 15%;
        width: 70%
    }
}
@media(max-width: 576px){
    .team_container .team_wrap .bio_wrap h2{
        font-size: 30px;
        line-height: 1.1;
    }
    .team_container .team_wrap .bio_wrap p{
        font-size: 16px;
        line-break: 1.2;
    }
    .team_container .team_wrap .teamD_wrap{
        padding: 0 25px;
    }
    .team_container .team_wrap .sociales .list-group-item{
        padding: 5px 8px;
    }
    .team_container .team_wrap .sociales .list-group-item:first-child{
        display: none;
    }
    .team_container .team_wrap .sociales img{
        width: 20px;
        height: 20px;
    }
}
</style>